
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { FileInfo } from '@/types/common'
import { DetailInfo } from '@/types/question'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile },
  filters: {
    isTopFilters (value: number) {
      return value === 2 ? '置顶' : '取消置顶'
    }
  }
})
export default class QuestionDetail extends Vue {
  private disabled = false
  private infoDetail = {
    tagList: [],
    resourceList: [],
    photo: {}
  }

  private info: DetailInfo = {
    answerContent: '',
    resourceList: [],
    answerUserNickName: ''
  }

  private rules = {
    answerContent: [{ required: true, message: '请输入回答内容', trigger: ['blur', 'change'] }],
    answerUserNickName: [{ required: true, message: '请输入回答人名称', trigger: ['blur', 'change'] }]
  }

  private answerList = []
  private loading = false
  private dialogTableVisible = false
  private submitShow = false
  private total = 0
  private page = 1
  private size = 10
  get activeNames () {
    const arr: number[] = []
    this.answerList.map((item, index) => {
      arr.push(index)
    })
    return arr
  }

  created () {
    this.getDetail()
    this.getAnswerList()
  }

  // 提问详情
  getDetail () {
    this.$axios.get(this.$apis.question.selectQuestionByQuestionId, {
      questionId: this.$route.params.id
    }).then(res => {
      this.infoDetail = res
    })
  }

  // 回答详情列表
  getAnswerList () {
    this.loading = true
    this.$axios.get(this.$apis.question.selectAnswerByPage, {
      questionId: this.$route.params.id,
      page: this.page,
      size: this.size,
      isDel: 0
    }).then(res => {
      this.answerList = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  // 图片上传返回
  onSuccess (file: FileInfo) {
    this.info.resourceList.push(file)
  }

  onAdd () {
    this.dialogTableVisible = true
  }

  // 删除回答
  onDetale (id: string) {
    this.disabled = true
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.question.deleteAnswer, {
          answerId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getAnswerList()
          })
      })
      .finally(() => {
        this.disabled = false
      })
  }

  // 置顶、取消置顶
  onTop (id: string) {
    this.disabled = true
    this.$axios.post(this.$apis.question.updateAnswerIsTop, {
      answerId: id
    }).then(() => {
      this.$message({
        message: '操作成功',
        type: 'success'
      })
      this.getAnswerList()
    }).finally(() => {
      this.disabled = false
    })
  }

  imgRemove (index: number) {
    this.info.resourceList.splice(index, 1)
  }

  close () {
    this.dialogTableVisible = false
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.question.insertAnswer, {
          ...this.info,
          questionId: this.$route.params.id
        }).then(() => {
          this.$message({
            message: '回答成功',
            type: 'success'
          });
          (this.$refs.info as ElForm).resetFields()
          this.close()
          this.getAnswerList()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
